import React, { useEffect, useMemo, useRef, useState } from 'react'
import useCompaniesStore from '@/stores/companies-store'
import './styles.scss'
import { ChevronRight, Plus } from 'react-feather'
import classNames from 'classnames'
import CompaniesFormDrawer from '@/components/CompaniesFormDrawer'

const getFirstTwoLetters = (name) => {
	return name
		.replace(/[^a-zA-Z ]/g, '')
		.slice(0, 2)
		.toUpperCase()
}

export default function ActiveCompanySelector() {
	const [open, setOpen] = useState(false)
	const [companiesFormDrawerOpen, setCompaniesFormDrawerOpen] = useState(false)
	const menuRef = useRef()

	const [activeCompany, companies, switchActiveCompany] = useCompaniesStore((state) => [
		state.activeCompany,
		state.companies,
		state.switchActiveCompany,
	])
	const companiesOptions = useMemo(() => {
		return companies
			.filter((company) => company.uid !== activeCompany?.uid)
			.sort((a, b) => a.name.localeCompare(b.name))
	}, [companies, activeCompany])

	const createNewCompanyHandler = () => {
		setCompaniesFormDrawerOpen(true)
		setOpen(false)
	}
	const onCompanyCreated = (company) => {
		switchActiveCompany(company.uid)
	}

	const swithActiveCompany = (company) => {
		switchActiveCompany(company.uid)
	}

	useEffect(() => {
		// click outside handler
		function handleClickOutside(event) {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setOpen(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [menuRef])

	if (!activeCompany) return null

	return (
		<>
			<div className="active-company-selector" ref={menuRef}>
				<div className="active-company-selector__activator-btn" onClick={() => setOpen(!open)}>
					<div className="active-company-selector__logo bg-primary">
						{getFirstTwoLetters(activeCompany.name)}
					</div>
					<div className="active-company-selector__name-cont">
						<div className="active-company-selector__name">{activeCompany.name}</div>
						<small className="active-company-selector__rut d-block">{activeCompany.rut}</small>
					</div>
					<div className="spacer"></div>
					<ChevronRight size={24} className="ml-50" color="#ccc" />
				</div>
				<div
					className={classNames('active-company-selector__menu-cont', {
						'active-company-selector__menu-cont--opened': open,
					})}
				>
					<div className="active-company-selector__menu">
						{companiesOptions.map((company) => {
							return (
								<div
									className="active-company-selector__opt"
									key={company.uid}
									onClick={() => swithActiveCompany(company)}
								>
									<div className="active-company-selector__logo bg-primary">
										{getFirstTwoLetters(company.name)}
									</div>
									<div className="active-company-selector__name-cont">
										<div className="active-company-selector__name">{company.name}</div>
										<small className="active-company-selector__rut d-block">{company.rut}</small>
									</div>
								</div>
							)
						})}
						<div className="active-company-selector__opt" onClick={createNewCompanyHandler}>
							<Plus size={28} color="var(--success)" />
							<span>Agregar empresa</span>
						</div>
					</div>
				</div>
			</div>
			<CompaniesFormDrawer
				open={companiesFormDrawerOpen}
				toggle={() => setCompaniesFormDrawerOpen(!companiesFormDrawerOpen)}
				onSave={onCompanyCreated}
			/>
		</>
	)
}

