import usePermissionsStore from '@/stores/permissions-store'
import useCompaniesStore from '@/stores/companies-store'
import { toast } from 'react-toastify'
import EventBus from '@/utility/event-bus'
import useEntitiesStore from './entities-store'

EventBus.once('login', async () => {
	let p1 = useEntitiesStore.getState().loadCurrencies()
	let p2 = usePermissionsStore.getState().loadPermissions()
	await Promise.all([p1, p2])

	const queryParams = new URLSearchParams(window.location.search)
	if (queryParams.get('forbidden') === 'true') {
		// remove query param
		let url = new URL(window.location.href)
		url.searchParams.delete('forbidden')
		window.history.replaceState({}, document.title, url)
		toast.error('No tienes permisos para realizar esta acción')
	}
})

EventBus.on('login', async () => {
	useCompaniesStore.getState().setCompanies([])
	await useCompaniesStore.getState().reloadCompanies()
})

