// ** React Imports
import { Fragment } from 'react'

// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown'

import themeConfig from '@/configs/themeConfig'

// ** Third Party Components
import { Sun, Moon } from 'react-feather'
import { NavLink } from 'reactstrap'

const ThemeNavbar = (props) => {
	// ** Props
	const { skin, setSkin, setMenuVisibility } = props

	// ** Function to toggle Theme (Light/Dark)
	const ThemeToggler = () => {
		if (skin === 'dark') {
			return <Sun className="ficon" onClick={() => setSkin('light')} />
		} else {
			return <Moon className="ficon" onClick={() => setSkin('dark')} />
		}
	}

	return (
		<Fragment>
			<div className="bookmark-wrapper d-flex align-items-center"></div>
			<ul className="nav navbar-nav align-items-center ml-auto">
				<div style={{ display: 'none' }}>
					<IntlDropdown />
				</div>

				{/* <UserDropdown /> */}
				<NavLink to="/dashboard" className="navbar-brand">
					<img
						src={themeConfig.app.appLogoImage}
						alt="logo"
						style={{ marginTop: '-15px', height: '45px' }}
					/>
					{/* <span className='brand-logo'>
              <img src={themeConfig.app.appLogoImage} alt='logo'/>
            </span> */}
					{/* <h2 className='brand-text mb-0'>{themeConfig.app.appName}</h2> */}
				</NavLink>
			</ul>
		</Fragment>
	)
}

export default ThemeNavbar
