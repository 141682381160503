import axios from 'axios'

const apis = { default: null }

function createApi(keyname, options = {}) {
	if (typeof keyname !== 'string') {
		options = keyname
		keyname = 'default'
	}

	if (apis[keyname]) throw new Error(`API "${keyname}" already exists`)

	const interceptors = []
	const callInterceptors = async (type, ...args) => {
		for (let x of interceptors) {
			if (x.type === type) {
				if ((await x.interceptor(...args)) === false) return false
			}
		}
	}

	const api = {}
	const axiosInstance = axios.create()

	apis[keyname] = api

	options = {
		baseUrl: '',
		...options,
		headers: {
			'Content-Type': 'application/json',
			...(options.headers || {}),
		},
	}

	api.intercept = function (type, interceptor) {
		const obj = { type, interceptor }
		interceptors.push(obj)
		return () => {
			let index = interceptors.indexOf(obj)
			if (index > -1) interceptors.splice(index, 1)
		}
	}

	api.fetch = async (req) => {
		req = req || {}
		req.method = req.method || 'get'
		req.url = options.baseUrl + (req.url || '')

		let hasFiles = !!req.files
		let contentType = hasFiles ? 'multipart/form-data' : 'application/json'
		req.headers = {
			'Content-Type': contentType,
			...(req.headers || {}),
		}

		if ((await callInterceptors('request', req)) === false) return { aborted: true }
		if ((await callInterceptors('before', req)) === false) return { aborted: true }
		if ((await req.before?.(req)) === false) return { aborted: true }

		req.setLoading?.(true)
		req.setError?.(null)
		req.setValidation?.({})

		const res = {}

		let data
		if (hasFiles) {
			data = new FormData()
			Object.keys(req.data || {}).forEach((key) => {
				data.append(key, req.data[key])
			})
			Object.keys(req.files).forEach((key) => {
				data.append(key, req.files[key])
			})
		} else {
			data = req.data
		}

		try {
			let response = await axiosInstance({
				method: req.method,
				url: req.url,
				headers: req.headers,
				data,
				params: req.params,
			})

			Object.assign(res, {
				success: true,
				status: response.status,
				data: response.data.data || {},
				error: null,
				validation: {},
			})
		} catch (err) {
			if (err.isAxiosError) {
				Object.assign(res, {
					success: false,
					status: err.response.status,
					data: err.response.data?.data || {},
					error: err.response.data?.error || null,
					validation: err.response.data?.validation || {},
				})
			} else {
				Object.assign(res, {
					success: false,
					status: 500,
					data: {},
					error: err.message,
					validation: {},
				})
			}
		}

		if (res.success) {
			req.setData?.(res.data)
		}

		req.setError?.(res.error)
		req.setValidation?.(res.validation)

		if (res.success) {
			if ((await callInterceptors('success', res, req)) === false) return { aborted: true }
			if ((await req.success?.(res)) === false) return { aborted: true }
		} else {
			if ((await callInterceptors('error', res, req)) === false) return { aborted: true }
			if ((await req.error?.(res)) === false) return { aborted: true }
		}

		if ((await callInterceptors('finally', res, req)) === false) return { aborted: true }
		if ((await req.finally?.(res)) === false) return { aborted: true }

		req.setLoading?.(false)

		return res
	}

	return api
}

function useApi(keyname, makeRequest) {
	if (typeof keyname !== 'string') {
		makeRequest = keyname
		keyname = 'default'
	}

	const api = apis[keyname]
	if (!api) throw new Error(`API "${keyname}" does not exist`)

	if (typeof makeRequest === 'function') {
		return (...args) => api.fetch(makeRequest(...args))
	} else {
		return api.fetch(makeRequest)
	}
}

function getApi(keyname) {
	return apis[keyname || 'default']
}

export { createApi, useApi, getApi }

