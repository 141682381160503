import React from 'react'
import { Link } from 'react-router-dom'
import themeConfig from '@/configs/themeConfig'
import img_seccion_footer_logo_start_up_chile from '@/assets/images/wasabil/img-seccion-footer-logo-start-up-chile.png'
import { HashLink } from 'react-router-hash-link'

export default function MainLayout_Footer() {
	return (
		<footer className="container main-layout__footer">
			<div className="row py-5">
				<div className="col-auto">
					<a
						href={process.env.REACT_APP_PUBLIC_URL}
						className="d-flex align-items-center link-body-emphasis text-decoration-none mb-1"
					>
						<img src={themeConfig.app.appLogoImage} style={{ height: '90px' }} alt="logo" />
					</a>
					<p className="text-black" style={{ maxWidth: '280px' }}>
						Automatizamos la facturas de compra por tus servicios internacionales
					</p>
					<img
						src={img_seccion_footer_logo_start_up_chile}
						style={{ maxWidth: '115px' }}
						className="img-fluid mb-3 mb-md-0"
						alt="logo"
					/>
				</div>

				<div className="col"></div>

				<div className="col-12 col-md">
					<h4 className="text-wasabil mb-2">
						<b>Producto</b>
					</h4>
					<ul className="nav flex-column">
						<li className="nav-item mb-1">
							<HashLink to="/#integraciones" className="nav-link p-0 text-black">
								Automatización de facturas
							</HashLink>
						</li>
						<li className="nav-item mb-1">
							<HashLink to="/#productos" className="nav-link p-0 text-black">
								Programación de facturas
							</HashLink>
						</li>
					</ul>
				</div>

				<div className="col-12 col-md">
					<h4 className="text-wasabil mb-2">
						<b>Soporte</b>
					</h4>
					<ul className="nav flex-column">
						<li className="nav-item mb-1">
							<Link to="/contacto" className="nav-link p-0 text-black">
								Contacto
							</Link>
						</li>
						<li className="nav-item mb-1">
							<Link to="/contacto" className="nav-link p-0 text-black">
								Centro de ayuda
							</Link>
						</li>
					</ul>
				</div>

				<div className="col-12 col-md">
					<h4 className="text-wasabil  mb-2">
						<b>Wasabil</b>
					</h4>
					<ul className="nav flex-column">
						<li className="nav-item mb-1">
							<Link to="/politicas-de-privacidad" className="nav-link p-0 text-black">
								Políticas de privacidad
							</Link>
						</li>
						<li className="nav-item mb-1">
							<Link to="/terminos-y-condiciones" className="nav-link p-0 text-black">
								Términos y condiciones
							</Link>
						</li>
					</ul>
				</div>
			</div>
			<hr className="m-0" />
			<div className="text-center py-2">
				© {new Date().getFullYear()} Wasabil - Todos lo derechos reservados
				<br />
				Propiedad de Zamunda SpA RUT 77.391.967-4.
			</div>
		</footer>
	)
}

