// ** Icons Import
import { Heart } from 'react-feather'
import { FormattedMessage } from 'react-intl'

const Footer = () => {
	return (
		<p className="clearfix mb-0">
			<span className="float-md-left d-block d-md-inline-block mt-25">
				COPYRIGHT © {new Date().getFullYear()}{' '}
				<a href="https://gux.tech" target="_blank" rel="noopener noreferrer">
					GUX
				</a>
				<span className="d-none d-sm-inline-block">
					<FormattedMessage id="rights_reserved" />
				</span>
			</span>
		</p>
	)
}

export default Footer
