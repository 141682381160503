// ** React Imports
import { Suspense, useEffect, useState } from 'react'

// ** Utils
import { useRouterTransition } from '@/utility/hooks/useRouterTransition'
import LayoutWrapper from '@/layouts/LayoutWrapper'
import { BrowserRouter as AppRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { routes } from './routes'
import { createApi } from '@/services/api'
import Spinner from '@/@core/components/spinner/Fallback-spinner'

import { toast } from 'react-toastify'

// ** Layouts
import BlankLayout from '@/layouts/BlankLayout'
import PanelLayout from '@/layouts/PanelLayout'
import MainLayout from '@/layouts/MainLayout'
import ScrollManager from '@/components/ScrollManager'
import useAuthStore from '@/stores/auth-store'
import PageNotFoundView from '@/views/misc/Error'
import { onForbidden } from '@/stores/permissions-store'

// if (window.location.hostname.startsWith('1')) {
// 	baseUrl = baseUrl.replace('localhost', '192.168.0.203')
// }
const api = createApi({ baseUrl: process.env.REACT_APP_API_URL })

const layoutsComponents = { BlankLayout, PanelLayout, MainLayout }

export default function App() {
	const [initialLoading, setInitialLoading] = useState(true)
	const [transition, setTransition] = useRouterTransition()
	const defaultLayout = 'PanelLayout'

	useEffect(() => {
		return api.intercept('request', (req) => {
			req.headers.language = 'es'
			const token = useAuthStore.getState().token
			if (token) {
				req.headers.Authorization = `Bearer ${token}`
			}
		})
	}, [])

	useEffect(() => {
		return api.intercept('error', async (res, req) => {
			if (res.status == 401) {
				if (window.location.pathname !== '/login') {
					window.sessionStorage.setItem('login_redirect', window.location.pathname)
					await useAuthStore.getState().logout()
					return false
				}
			}

			if (res.status == 403) {
				onForbidden()
				return false
			}

			if (res.error && req.toastError !== false) {
				toast.error(res.error)
			}
		})
	}, [])

	useEffect(() => {
		return api.intercept('success', (res, req) => {
			if (req.successMessage) {
				toast.success(req.successMessage)
			}
		})
	}, [])

	useEffect(() => {
		if (!useAuthStore.getState().token) {
			setInitialLoading(false)
			return
		}

		setInitialLoading(true)
		useAuthStore
			.getState()
			.initializeUser()
			.then((user) => {
				if (user) setInitialLoading(false)
			})
	}, [])

	if (initialLoading) {
		return <Spinner />
	}

	const ResolveRoutes = () => {
		return Object.entries(layoutsComponents).map(([layout, LayoutComponent], index) => {
			const layoutRoutes = routes.filter((route) => {
				return route.layout === layout || (!route.layout && defaultLayout === layout)
			})
			const routesPaths = layoutRoutes.map((route) => route.path)

			return (
				<Route
					path={routesPaths}
					exact
					key={index}
					render={(props) => {
						const currentPath = props.match.path
						const route = layoutRoutes.find((route) => route.path === currentPath)

						if (route.beforeRoute) {
							for (let fn of route.beforeRoute) {
								const redirectTo = fn()
								if (redirectTo) {
									if (redirectTo.startsWith('http')) {
										window.location.href = redirectTo
										return null
									} else {
										return <Redirect to={redirectTo} />
									}
								}
							}
						}

						const layoutWrapperProps = {}
						if (route.meta) layoutWrapperProps.routeMeta = route.meta
						if (route.className) layoutWrapperProps.wrapperClass = route.className
						if (route.appLayout) layoutWrapperProps.appLayout = route.appLayout

						const routerProps = { ...props, meta: route.meta }
						return (
							<LayoutComponent routerProps={routerProps}>
								<Suspense fallback={null}>
									<LayoutWrapper
										transition={transition}
										setTransition={setTransition}
										{...layoutWrapperProps}
									>
										<route.component {...props} />
									</LayoutWrapper>
								</Suspense>
							</LayoutComponent>
						)
					}}
				></Route>
			)
		})
	}

	return (
		<Suspense fallback={<Spinner />}>
			<AppRouter basename="">
				<Switch>
					{ResolveRoutes()}
					<Route path="/*" component={PageNotFoundView} />
				</Switch>
				<ScrollManager />
			</AppRouter>
		</Suspense>
	)
}

