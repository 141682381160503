// eventBus.js
const subscribers = []

const EventBus = Object.freeze({
	on: (event, callback) => {
		if (!subscribers[event]) {
			subscribers[event] = []
		}
		subscribers[event].push(callback)
		return () => {
			EventBus.off(event, callback)
		}
	},
	off: (event, callback) => {
		if (subscribers[event]) {
			subscribers[event] = subscribers[event].filter((x) => x !== callback)
		}
	},
	once: (event, callback) => {
		const onceCallback = async (data) => {
			EventBus.off(event, onceCallback)
			await callback(data)
		}
		return EventBus.on(event, onceCallback)
	},
	emit: async (event, data, { stoppable = false } = {}) => {
		if (subscribers[event]) {
			if (stoppable) {
				for (const callback of subscribers[event]) {
					if ((await callback(data)) === false) return
				}
			} else {
				await Promise.all(
					subscribers[event].map(async (callback) => {
						await callback(data)
					})
				)
			}
		}
	},
})

export default EventBus

