import React, { useEffect, useState } from 'react'
import './comune-selector.scss'
import { Input } from 'reactstrap'
import { useApi } from '@/services/api'

let comunesCache = window.localStorage.getItem('comunes')
if (comunesCache) comunesCache = JSON.parse(comunesCache)

export default function ComuneSelector(props) {
	const [comunes, setComunes] = useState([])
	useEffect(() => {
		if (comunesCache) {
			setComunes(comunesCache)
			return
		}

		useApi({
			url: '/comunes',
			setData: (data) => {
				comunesCache = data.map((e) => ({ label: e.name, value: e.id }))
				window.localStorage.setItem('comunes', JSON.stringify(comunesCache))
				setComunes(comunesCache)
			},
		})
	}, [])

	return (
		<Input {...props} type="select" className={`comune-selector ${props.className || ''}`}>
			<option value="">Seleccionar...</option>
			{comunes.map((e) => (
				<option key={`comune-${e.value}`} value={e.value}>
					{e.label}
				</option>
			))}
		</Input>
	)
}
