import React from 'react'
import MainLayout_Footer from './MainLayout_Footer'
import MainLayout_Navbar from './MainLayout_Navbar'
import './main-layout.scss'

export default function MainLayout({ children }) {
	return (
		<div className="main-layout blank-page">
			<div className="main-layout app-content content">
				<div className="content-wrapper">
					<div className="content-body">
						<MainLayout_Navbar />
						<div className="main-layout__content">{children}</div>
						<MainLayout_Footer />
					</div>
				</div>
			</div>
		</div>
	)
}
