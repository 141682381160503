import React, { useEffect, useState } from 'react'

export default function BlankLayout({ children }) {
	return (
		<div className="blank-page">
			<div className="app-content content">
				<div className="content-wrapper">
					<div className="content-body">{children}</div>
				</div>
			</div>
		</div>
	)
}
