// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import companies from '@/componentswasabil/security/store/reducer'
import documents from '@/componentswasabil/documents/store/reducer'

const rootReducer = combineReducers({
	companies,
	navbar,
	layout,
	documents,
})

export default rootReducer
