import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '@/@core/components/sidebar'
import MainLayout_Logo from './MainLayout_Logo'
import useAuthStore from '@/stores/auth-store'
// import { HashLink } from 'react-router-hash-link'

export default function MainLayout_Navbar() {
	const [openSidebar, setOpenSidebar] = useState(false)
	const isLoggedIn = useAuthStore((state) => state.isLoggedIn)

	return (
		<nav className="main-layout__navbar navbar navbar-light py-2 px-md-3 mx-0">
			<div className="row d-flex flex-wrap align-items-center justify-content-between d-md-none w-100 mx-0">
				<div className="col-auto px-0 text-right">
					<MainLayout_Logo />
				</div>
				<div className="col-auto px-0">
					<button className="navbar-toggler" type="button" onClick={() => setOpenSidebar(true)}>
						<span className="navbar-toggler-icon text-dark px-1" style={{ fontSize: '1.5rem' }}></span>
					</button>
				</div>
			</div>
			<Sidebar
				size="lg"
				open={openSidebar}
				title={'Wasabil'}
				headerClassName="mb-1 d-flex align-items-center"
				contentClassName="p-0"
				toggleSidebar={() => setOpenSidebar(!openSidebar)}
				returnFocusAfterClose={false}
			>
				<ul className="nav navbar-nav mr-auto">
					{/* <li>
						<Link
							to="/#productos"
							onClick={() => setOpenSidebar(false)}
							className="nav-link link-secondary"
						>
							Productos
						</Link>
					</li> */}
					<li>
						<Link to="/#caracteristicas" onClick={() => setOpenSidebar(false)} className="nav-link">
							Características
						</Link>
					</li>
					<li>
						<Link to="/#precios" onClick={() => setOpenSidebar(false)} className="nav-link">
							Precios
						</Link>
					</li>
					<li>
						<Link to="/#faq" onClick={() => setOpenSidebar(false)} className="nav-link">
							FAQs
						</Link>
					</li>
					<li>
						<Link to="/contacto" onClick={() => setOpenSidebar(false)} className="nav-link">
							Contacto
						</Link>
					</li>
				</ul>
				<hr />
				{isLoggedIn() ? (
					<div className="text-center">
						<Link to="/dashboard" className="btn btn-primary">
							Mi Cuenta
						</Link>
					</div>
				) : (
					<div className="text-center">
						<Link to="/login" className="link-wasabil">
							Iniciar sesión
						</Link>
						<hr />
						<Link to="/register?type=1" className="btn btn-primary">
							Empezar gratis
						</Link>
					</div>
				)}
			</Sidebar>
			<div className="col-12 d-none d-md-block">
				<div className="row d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
					<div className="col-md-auto mb-2 mb-md-0">
						<div className="d-inline-flex link-body-emphasis text-decoration-none">
							<MainLayout_Logo />
						</div>
					</div>
					<ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
						{/* <li>
							<Link to="/#productos" className="nav-link link-secondary">
								Productos
							</Link>
						</li> */}
						<li>
							<Link to="/#caracteristicas" className="nav-link">
								Características
							</Link>
						</li>
						<li>
							<Link to="/#precios" className="nav-link">
								Precios
							</Link>
						</li>
						<li>
							<Link to="/#faq" className="nav-link">
								FAQs
							</Link>
						</li>
						<li>
							<Link to="/contacto" className="nav-link">
								Contacto
							</Link>
						</li>
					</ul>

					<div className="col-md-auto text-end px-0">
						<div className="row d-flex align-items-center justify-content-center justify-content-md-end">
							{isLoggedIn() ? (
								<div className="col-auto">
									<Link to="/dashboard" className="btn btn-primary">
										Mi Cuenta
									</Link>
								</div>
							) : (
								<>
									<div className="col-auto">
										<Link to="/login" className="link-wasabil">
											Iniciar sesión
										</Link>
									</div>
									<div className="col-auto">
										<Link to="/register?type=1" className="btn btn-primary">
											Empezar gratis
										</Link>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</nav>
	)
}

