import logo from '@/assets/images/logo/wasabil_200x200.webp'

export default function SpinnerComponent() {
	return (
		<div className="fallback-spinner vh-100">
			<img className="fallback-logo" src={logo} alt="logo" height="60px" />
			<div className="loading">
				<div className="effect-1 effects"></div>
				<div className="effect-2 effects"></div>
				<div className="effect-3 effects"></div>
			</div>
		</div>
	)
}

