// ** React Imports
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from '@/utility/context/ThemeColors'
import { IntlProviderWrapper } from '@/utility/context/Internationalization'

// ** Spinner (Splash Screen)

// ** Ripple Button
import '@/@core/components/ripple-button'
import '@/styles/index.scss'
import '@/stores/auth-store'
import '@/stores/permissions-store'
import '@/stores/companies-store'
import '@/stores/notifications-store'
import '@/stores/event-listeners'

import App from './App'

ReactDOM.render(
	<Provider store={store}>
		<ThemeContext>
			<IntlProviderWrapper>
				<App />
				<ToastContainer newestOnTop />
			</IntlProviderWrapper>
		</ThemeContext>
	</Provider>,
	document.getElementById('root')
)

// ** Service Worker
if (process.env.NODE_ENV === 'production') {
	import('./serviceWorker').then((serviceWorker) => {
		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		serviceWorker.unregister()
	})
}

