import React, { useMemo } from 'react'
import './companies-form-drawer.scss'
import Sidebar from '@/@core/components/sidebar'
import CompaniesForm from '@/components/CompaniesForm'

export default function CompaniesFormDrawer({ uuid, open, toggle, onSave, viewOnly }) {
	const title = useMemo(() => {
		return uuid ? 'Editar Empresa' : 'Nueva Empresa'
	}, [uuid])

	return (
		<div className="companies-form-drawer">
			<Sidebar
				size="lg"
				style={{ maxWidth: '900px', width: '85%' }}
				open={open}
				title={title}
				headerClassName="mb-1"
				contentClassName="pt-0 bg-light"
				toggleSidebar={toggle}
			>
				<CompaniesForm
					uuid={uuid}
					onSave={(company, isNew) => {
						onSave?.(company, isNew)
						toggle()
					}}
					viewOnly={viewOnly}
				/>
			</Sidebar>
		</div>
	)
}

