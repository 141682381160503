export const API_URL = process.env.REACT_APP_API_URL
export const LS_USER_DATA = process.env.REACT_APP_LS_USER_DATA
export const EMAIL_PLACEHOLDER = process.env.REACT_APP_EMAIL_PLACEHOLDER
export const LS_LANGUAGE_INITIAL = process.env.REACT_APP_LS_LANGUAGE_INITIAL
export const LS_ROLE_INITIAL = process.env.REACT_APP_LS_ROLE_INITIAL
export const LS_SKIN_INITIAL = process.env.REACT_APP_LS_SKIN_INITIAL
export const LS_MENU_COLLAPSE = process.env.REACT_APP_LS_MENU_COLLAPSE
export const ACTION_EDIT = 'edit'
export const ACTION_VIEW = 'view'
export const ACTION_ADD = 'add'
export const DEFAULT_STATUS_ID = 1
export const DEFAULT_COUNTRY_ID = 43
export const DEFAULT_COMUNE_ID = 1
export const ROL_CLIENTE = 1
export const ROL_ADMIN_EMPRESA = 2
export const ROL_SUPER_ADMIN = 3
export const CURRENT_PAGE = 1
export const RECORDS_PER_PAGE = 10
export const OPTIONSRECORDS = [
	{
		label: 10,
		value: 10,
	},
	{
		label: 25,
		value: 25,
	},
	{
		label: 50,
		value: 50,
	},
]
export const JSON_YES = JSON.parse(`{"es":"Si","en":"Yes"}`)
export const JSON_NO = JSON.parse(`{"es":"No","en":"No"}`)
export const TYPE_CALL_SUPPLIER = {
	sidebar: 'sidebar',
	normal: 'normal',
}

export const NONE_VALUE = ''
export const ZERO_VALUE = 0

export const KEY_DOWN_BACKSPACE = 'Backspace'
export const KEY_DOWN_ARROW_UP = 'ArrowUp'
export const KEY_DOWN_ARROW_DOWN = 'ArrowDown'
export const KEY_DOWN_ARROW_LEFT = 'ArrowLeft'
export const KEY_DOWN_ARROW_RIGHT = 'ArrowRight'
export const KEY_DOWN_TAB = 'Tab'

export const CODE_DOCUMENT_STATUS_DRAFT = 1
export const CODE_DOCUMENT_STATUS_REQUESTING_SII = 6
