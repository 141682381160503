import React, { useEffect, useState, useMemo } from 'react'
import { FormGroup, Input, Row, Col, Label, Button, Spinner } from 'reactstrap'
import { Briefcase, Save } from 'react-feather'
import 'bs-stepper/dist/css/bs-stepper.min.css'
import '@/styles/core/base/plugins/forms/form-wizard.scss'
import { useApi } from '@/services/api'
import './companies-form.scss'
import ComuneSelector from '@/components/ComuneSelector'
import useCompaniesStore from '@/stores/companies-store'
import classNames from 'classnames'

export default function CompaniesForm({ uuid, onCompanyLoaded, onSave, viewOnly }) {
	const [loading, setLoading] = useState(true)
	const [company, setCompany] = useState({})
	const [validation, setValidation] = useState({})

	const isNew = useMemo(() => {
		return !uuid
	}, [uuid])

	useEffect(() => {
		if (!isNew) {
			useApi({
				url: `/companies/${uuid}`,
				setLoading,
				success: ({ data }) => {
					useCompaniesStore.getState().updateCompany(data)
					setCompany(data)
					onCompanyLoaded?.(data)
				},
			})
		} else {
			setCompany({
				name: '',
				rut: '',
				comune_id: '',
				city: '',
				address: '',
				giro: '',
			})
			onCompanyLoaded?.(null)
			setLoading(false)
		}
	}, [uuid, isNew])

	const [saveLoading, setSaveLoading] = useState(false)
	const saveCompany = useApi(() => ({
		method: isNew ? 'POST' : 'PUT',
		url: `/companies${isNew ? '' : `/${uuid}`}`,
		data: { ...company },
		setLoading: setSaveLoading,
		setValidation,
		successMessage: `Listo! Tu empresa ha sido ${isNew ? 'creada' : 'actualizada'}`,
		success: ({ data }) => {
			useCompaniesStore.getState().updateCompany(data)
			onSave?.(data, isNew)
		},
	}))

	if (loading) {
		return (
			<div className="d-flex justify-content-center py-4">
				<Spinner />
			</div>
		)
	}

	return (
		<div
			className={classNames(`companies-form px-3`, {
				'companies-form--viewonly': viewOnly && !isNew,
			})}
		>
			<div className="horizontal-wizard ">
				<div className="bs-stepper linear">
					<div className="bs-stepper-header">
						<div className="step active">
							<button type="button" className="step-trigger">
								<span className="bs-stepper-box">
									<Briefcase size={18} />
								</span>
								<span className="bs-stepper-label">
									<span className="bs-stepper-title">Detalles de la Empresa</span>
									<span className="bs-stepper-subtitle">Ingresa todos los datos de tu empresa</span>
								</span>
							</button>
						</div>
					</div>
				</div>
				<div className="bs-stepper-content py-2 px-3" style={{ background: 'white', paddingTop: '5px' }}>
					<Row>
						<FormGroup tag={Col} md="6">
							<Label>Razón Social</Label>
							<Input
								type="text"
								placeholder="Mi Empresa SPA"
								value={company.name}
								onChange={(e) => {
									setCompany({ ...company, name: e.target.value })
								}}
								className={validation.name ? 'is-invalid' : ''}
							/>
							{!!validation.name && <div className="form-error-message">{validation.name}</div>}
						</FormGroup>
						<FormGroup tag={Col} md="4">
							<Label>RUT</Label>
							<Input
								type="text"
								placeholder="12345678-5"
								value={company.rut}
								onChange={(e) => {
									setCompany({ ...company, rut: e.target.value })
								}}
								className={validation.rut ? 'is-invalid' : ''}
								disabled={!!company.issuer_id}
							/>
							{!!validation.rut && <div className="form-error-message">{validation.rut}</div>}
						</FormGroup>
					</Row>
					<Row>
						<FormGroup tag={Col} md="6">
							<Label>Comuna</Label>
							<ComuneSelector
								type="text"
								value={company.comune_id}
								onChange={(e) => {
									setCompany({ ...company, comune_id: e.target.value })
								}}
								className={validation.comune_id ? 'is-invalid' : ''}
							/>
							{!!validation.comune_id && (
								<div className="form-error-message">{validation.comune_id}</div>
							)}
						</FormGroup>
						<FormGroup tag={Col} md="6">
							<Label>Ciudad / Localidad</Label>
							<Input
								type="text"
								value={company.city}
								onChange={(e) => {
									setCompany({ ...company, city: e.target.value })
								}}
								className={validation.city ? 'is-invalid' : ''}
							/>
							{!!validation.city && <div className="form-error-message">{validation.city}</div>}
						</FormGroup>
						<FormGroup tag={Col} md="12">
							<Label>Dirección</Label>
							<Input
								type="text"
								value={company.address}
								onChange={(e) => {
									setCompany({ ...company, address: e.target.value })
								}}
								className={validation.address ? 'is-invalid' : ''}
							/>
							{!!validation.address && <div className="form-error-message">{validation.address}</div>}
						</FormGroup>
					</Row>
					<Row>
						<FormGroup tag={Col} md="12">
							<Label>Giro del Negocio</Label>
							<Input
								type="text"
								placeholder="Servicios digitales"
								value={company.giro}
								onChange={(e) => {
									setCompany({ ...company, giro: e.target.value })
								}}
								className={validation.giro ? 'is-invalid' : ''}
							/>
							{!!validation.giro && <div className="form-error-message">{validation.giro}</div>}
						</FormGroup>
					</Row>
				</div>
			</div>

			{(!viewOnly || isNew) && (
				<Button
					block
					className="mt-2 mb-3"
					color="primary"
					disabled={saveLoading}
					onClick={() => saveCompany()}
				>
					{saveLoading ? (
						<Spinner />
					) : (
						<>
							<Save className="mr-2"></Save>
							{isNew ? 'Registrar Empresa' : 'Actualizar Empresa'}
						</>
					)}
				</Button>
			)}
		</div>
	)
}

