import { Link } from 'react-router-dom'
import themeConfig from '@/configs/themeConfig'

export default function MainLayout_Logo() {
	return (
		<Link className="brand-logo" to={`${process.env.REACT_APP_PUBLIC_URL}`}>
			<img
				src={themeConfig.app.appLogoImage}
				style={{ height: '50px', marginTop: '-22px' }}
				alt="logo wasabil"
			/>
		</Link>
	)
}

