// ** Third Party Components
import ReactCountryFlag from 'react-country-flag'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

// ** Internationalization Context
import { getDataByLanguage } from '@/utility/Utils'
import useAuthStore from '@/stores/auth-store'
import languages from '@/configs/languages'

const IntlDropdown = () => {
	// ** Context
	const [locale, setLocale] = useAuthStore((state) => [state.locale, state.setLocale])

	// ** Function to switch Language
	const handleLangUpdate = (e, lang) => {
		e.preventDefault()
		setLocale(lang)
	}

	const getDescriptionLanguage = (language) => {
		const lang = languages.find((lang) => lang.alias === language)
		return getDataByLanguage(language, lang['description'])
	}

	const getCountryCodeLanguage = (language) => {
		const lang = languages.find((lang) => lang.alias === language)
		return lang.country_code
	}

	return (
		<UncontrolledDropdown href="/" tag="li" className="dropdown-language nav-item">
			<DropdownToggle href="/" tag="a" className="nav-link" onClick={(e) => e.preventDefault()}>
				<ReactCountryFlag
					className="country-flag flag-icon"
					// countryCode={intlContext.locale === 'en' ? 'us' : intlContext.locale}
					countryCode={getCountryCodeLanguage(locale)}
					svg
				/>
				<span className="selected-language">{getDescriptionLanguage(locale)}</span>
			</DropdownToggle>
			<DropdownMenu className="mt-0" right>
				{languages.map((lang) => {
					return (
						<DropdownItem
							href="/"
							tag="a"
							onClick={(e) => handleLangUpdate(e, lang.alias)}
							key={lang.id}
						>
							<ReactCountryFlag className="country-flag" countryCode={lang.country_code} svg />
							<span className="ml-1">{getDataByLanguage(lang.alias, lang.description)}</span>
						</DropdownItem>
					)
				})}
			</DropdownMenu>
		</UncontrolledDropdown>
	)
}

export default IntlDropdown

