export function getCookie(name) {
	let cookieValue = null
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';')
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim()
			if (cookie.substring(0, name.length + 1) === `${name}=`) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
				break
			}
		}
	}
	return cookieValue
}

export function setCookie(name, value, days, domain) {
	let cookie = [`${name}=${value || ''}`]
	if (days) {
		const date = new Date()
		const addedTime = days * 24 * 60 * 60 * 1000
		date.setTime(date.getTime() + addedTime)
		cookie.push(`Expires=${date.toUTCString()}`)
	}
	cookie.push('Path=/')
	if (domain) cookie.push(`Domain=${domain}`)
	document.cookie = cookie.join(';')
}

export function deleteCookie(name, domain) {
	let cookie = [`${name}=`, 'Path=/']
	if (domain) cookie.push(`Domain=${domain}`)
	cookie.push('Expires=Thu, 01 Jan 1970 00:00:01 GMT')
	document.cookie = cookie.join(';')
}

