import React from 'react'
import themeConfig from '@/configs/themeConfig'
import { Link } from 'react-router-dom'

const Logo = ({ to = '/dashboard' }) => {
	let img = <img src={themeConfig.app.appLogoImage} style={{ height: '50px' }} alt="logo" />

	if (to.startsWith('http')) {
		return (
			<a href={to} className="brand-logo">
				{img}
			</a>
		)
	}
	return (
		<Link className="brand-logo" to={to}>
			{img}
		</Link>
	)
}

export default Logo

