import { Button } from 'reactstrap'
import errorImg from '@/assets/images/pages/error.svg'

import '@/styles/core/base/pages/page-misc.scss'
import Logo from '@/@core/components/logo'

const Error = () => {
	return (
		<div className="misc-wrapper">
			<Logo viewname={true} />
			<div className="misc-inner p-2 p-sm-3">
				<div className="w-100 text-center">
					<h2 className="mb-1">Página No Encontrada 🕵🏻‍♀️</h2>
					<p className="mb-2">😖 El sitio no ha sido encontrado.</p>
					<Button
						tag="a"
						href={process.env.REACT_APP_PUBLIC_URL}
						color="primary"
						className="btn-sm-block mb-2"
					>
						Ir a Wasabil
					</Button>
					<img className="img-fluid" src={errorImg} alt="Not authorized page" />
				</div>
			</div>
		</div>
	)
}
export default Error

