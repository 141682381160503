import { useApi } from '@/services/api'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

function getInitialActiveCompanyUid() {
	const url = new URL(window.location.href)
	return (
		url.searchParams.get('c') ||
		window.sessionStorage.getItem('activeCompany') ||
		window.localStorage.getItem('activeCompany')
	)
}
function saveStorage(uid) {
	window.sessionStorage.setItem('activeCompany', uid)
	window.localStorage.setItem('activeCompany', uid)
}

function getCompanyUrl(uid) {
	const url = new URL(window.location.href)
	url.searchParams.set('c', uid)
	return url.toString()
}

const useCompaniesStore = create(
	subscribeWithSelector((set, get) => ({
		companies: [],
		setCompanies: (companies) => {
			companies = companies || []
			set({ companies })
			get().setActiveCompany(getInitialActiveCompanyUid())
		},
		companiesLoading: false,
		setCompaniesLoading: (v) => set({ companiesLoading: v }),
		reloadCompanies: async (opts = {}) => {
			return useApi({
				...opts,
				url: '/companies',
				setLoading: (v) => get().setCompaniesLoading(v),
				success: ({ data }) => {
					get().setCompanies(data)
				},
			})
		},
		reloadActiveCompany: async (opts = {}) => {
			const uid = get().activeCompany?.uid
			if (!uid) return
			return useApi({
				...opts,
				url: `/companies/${uid}`,
				setLoading: (v) => get().setCompaniesLoading(v),
				success: ({ data }) => {
					get().updateCompany(data)
				},
			})
		},
		updateCompany: (company) => {
			const companies = [...get().companies]
			let idx = companies.findIndex((c) => c.uid === company.uid)
			if (idx !== -1) {
				companies[idx] = company
				get().setCompanies(companies)
			} else {
				get().setCompanies([...companies, company])
			}
		},
		activeCompany: null,
		setActiveCompany: (uid) => {
			const { companies } = get()
			let activeCompany = uid ? companies.find((c) => c.uid == uid) : null
			if (!activeCompany && companies.length) {
				activeCompany = companies[0]
			}
			set({ activeCompany: activeCompany || null })
			if (activeCompany) {
				saveStorage(activeCompany.uid)
			}
		},
		switchActiveCompany: (uid) => {
			let activeCompany = get().companies.find((c) => c.uid == uid)
			if (!activeCompany) return
			saveStorage(activeCompany.uid)
			window.location.href = getCompanyUrl(activeCompany.uid)
		},
	}))
)

export default useCompaniesStore

