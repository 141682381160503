// import React from 'react'
import { useEffect, useLayoutEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const scrollToHash = (hash) => {
	setTimeout(() => {
		let element = document.getElementById(hash.replace('#', ''))
		if (!element) {
			let timeoutId = null
			const observer = new MutationObserver((mutationsList, observer) => {
				const element = document.getElementById(hash.replace('#', ''))
				if (element) {
					setTimeout(() => {
						element.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
							inline: 'nearest',
						})
					}, 1500)
					observer.disconnect()
					if (timeoutId) clearTimeout(timeoutId)
				}
			})

			timeoutId = setTimeout(() => observer.disconnect(), 5000)
			observer.observe(document.body, { childList: true, subtree: true })
		} else {
			// smooth scroll
			setTimeout(() => {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'nearest',
				})
			}, 100)
		}
	}, 10)
}

export default function ScrollManager() {
	const history = useHistory()
	const { hash } = useLocation()

	useLayoutEffect(() => {
		if (hash) scrollToHash(hash)

		const unlisten = history.listen((location, action) => {
			if (action === 'PUSH') {
				if (location.hash) {
					scrollToHash(location.hash)
				} else {
					setTimeout(() => window.scrollTo(0, 0), 0)
				}
			}
		})

		return () => {
			unlisten()
		}
	}, [])

	return null
}

