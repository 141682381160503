// ** React Imports
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import { handleMenuCollapsed } from '@/redux/actions/layout'

// ** Third Party Components
import classnames from 'classnames'
import { Navbar } from 'reactstrap'

// ** Custom Components
// import Customizer from '@/@core/components/customizer'
import FooterComponent from './footer'
import NavbarComponent from './navbar'
import SidebarComponent from './menu/vertical-menu'

// ** Custom Hooks
import { useSkin } from '@/utility/hooks/useSkin'
import { useNavbarType } from '@/utility/hooks/useNavbarType'
import { useFooterType } from '@/utility/hooks/useFooterType'
import { useNavbarColor } from '@/utility/hooks/useNavbarColor'

// ** Styles
import '@/styles/core/base/core/menu/menu-types/vertical-menu.scss'
import '@/styles/core/base/core/menu/menu-types/vertical-overlay-menu.scss'
import './panel-layout.scss'

import NotificationsBar from '@/components/NotificationsBar'

export default function PanelLayout(props) {
	// ** Props
	const { children, navbar, menu, routerProps, currentActiveItem } = props
	// ** Hooks
	const [skin, setSkin] = useSkin()
	const [navbarType, setNavbarType] = useNavbarType()
	const [footerType, setFooterType] = useFooterType()
	const [navbarColor, setNavbarColor] = useNavbarColor()

	// ** States
	const [menuVisibility, setMenuVisibility] = useState(false)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)

	// ** Store Vars
	const dispatch = useDispatch()
	const layoutStore = useSelector((state) => state.layout)

	// ** Vars
	const location = useLocation()
	const menuCollapsed = layoutStore.menuCollapsed
	const isHidden = layoutStore.menuHidden

	// ** Toggles Menu Collapsed
	const setMenuCollapsed = (val) => dispatch(handleMenuCollapsed(val))

	//** This function will detect the Route Change and will hide the menu on menu item click
	useEffect(() => {
		if (menuVisibility && windowWidth < 1200) {
			setMenuVisibility(false)
		}
	}, [location])

	//** Sets Window Size & Layout Props
	useEffect(() => {
		window?.addEventListener('resize', () => setWindowWidth(window.innerWidth))
	}, [windowWidth])

	// ** Vars
	const footerClasses = {
		static: 'footer-static',
		sticky: 'footer-fixed',
		hidden: 'footer-hidden',
	}

	const navbarWrapperClasses = {
		floating: 'navbar-floating',
		sticky: 'navbar-sticky',
		static: 'navbar-static',
		hidden: 'navbar-hidden',
	}

	const navbarClasses = {
		floating: 'floating-nav',
		sticky: 'fixed-top',
		static: 'navbar-static-top',
		hidden: 'd-none',
	}

	const bgColorCondition = navbarColor !== '' && navbarColor !== 'light' && navbarColor !== 'white'
	const [notifShown, setNotifShown] = useState(false)

	useEffect(() => {
		document.getElementsByTagName('html')[0].classList.add('html-panel-layout')
		return () => document.getElementsByTagName('html')[0].classList.remove('html-panel-layout')
	}, [])

	return (
		<div
			className={classnames('panel-layout', {
				'panel-layout--with-notification': notifShown,
			})}
		>
			<NotificationsBar onShow={() => setNotifShown(true)} onHide={() => setNotifShown(false)} />
			<div
				className={classnames(
					'wrapper vertical-layout',
					navbarWrapperClasses[navbarType] || 'navbar-floating',
					footerClasses[footerType] || 'footer-static',
					{
						'vertical-menu-modern': windowWidth >= 1200,
						'menu-collapsed': menuCollapsed && windowWidth >= 1200,
						'menu-expanded': !menuCollapsed && windowWidth > 1200,
						'vertical-overlay-menu': windowWidth < 1200,
						'menu-hide': !menuVisibility && windowWidth < 1200,
						'menu-open': menuVisibility && windowWidth < 1200,
					}
				)}
				{...(isHidden ? { 'data-col': '1-column' } : {})}
			>
				{!isHidden ? (
					<SidebarComponent
						skin={skin}
						menu={menu}
						menuCollapsed={menuCollapsed}
						menuVisibility={menuVisibility}
						setMenuCollapsed={setMenuCollapsed}
						setMenuVisibility={setMenuVisibility}
						routerProps={routerProps}
						currentActiveItem={currentActiveItem}
					/>
				) : null}

				<Navbar
					expand="lg"
					light={skin !== 'dark'}
					dark={skin === 'dark' || bgColorCondition}
					color={bgColorCondition ? navbarColor : undefined}
					className={classnames(
						`header-navbar navbar align-items-center ${
							navbarClasses[navbarType] || 'floating-nav'
						} navbar-shadow header-navbar-custom`
					)}
				>
					<div className="navbar-container d-flex content">
						{navbar ? (
							navbar({ setMenuVisibility, skin, setSkin })
						) : (
							<NavbarComponent setMenuVisibility={setMenuVisibility} skin={skin} setSkin={setSkin} />
						)}
					</div>
				</Navbar>
				<div style={{ minHeight: '100vh' }}>{children}</div>

				{/* Vertical Nav Menu Overlay */}
				<div
					className={classnames('sidenav-overlay', {
						show: menuVisibility,
					})}
					onClick={() => setMenuVisibility(false)}
				></div>
				<footer
					className={classnames(`footer footer-light ${footerClasses[footerType] || 'footer-static'}`, {
						'd-none': footerType === 'hidden',
					})}
				>
					<FooterComponent footerType={footerType} footerClasses={footerClasses} />
				</footer>
			</div>
		</div>
	)
}
