export default [
	{
		id: 1,
		alias: 'es',
		country_code: 'es',
		description: {
			es: 'Castellano',
			en: 'Spanish',
		},
	},
	{
		id: 2,
		alias: 'en',
		country_code: 'us',
		description: {
			es: 'Inglés',
			en: 'English',
		},
	},
]
