import { useApi } from '@/services/api'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

const useEntitiesStore = create(
	subscribeWithSelector((set, get) => ({
		currencies: null,
		loadCurrencies: (opts = {}) => {
			return useApi({
				...opts,
				url: '/currencies',
				success: ({ data }) => {
					set({ currencies: data })
				},
			})
		},
		invoicePaymentMethods: [
			{ id: 'credito', name: 'Crédito' },
			{ id: 'contado', name: 'Contado' },
		],
	}))
)

export default useEntitiesStore

