import classNames from 'classnames'
import wasabilitoImg from './wasabilito-aplaudiendo.png'
import './styles.scss'
import { Button } from 'reactstrap'
import { Gift } from 'react-feather'
import { NavLink } from 'react-router-dom'

export default function ReferralProgramCard({ className }) {
	return (
		<NavLink to="/referral-program" className={classNames(`referral-program-card`, className)}>
			<img src={wasabilitoImg} alt="Wasabilito aplaudiendo" className="mb-75" />
			<b style={{ lineHeight: '1' }}>¡Obtén suscripción gratuita!</b>
			<span style={{ fontSize: '10px' }}>Recomienda a Wasabil</span>
			<Button color="light" className="d-flex align-items-center mt-1" size="sm">
				<Gift size={12} className="mr-50" />
				<span>¿Qué debo hacer?</span>
			</Button>
		</NavLink>
	)
}

