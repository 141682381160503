// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
// import messagesEn from '@/@core/assets/data/locales/en.json'
import messagesEs from '@/@core/assets/data/locales/es.json'

// ** User Language Data
// import userMessagesEn from '@/assets/data/locales/en.json'
import userMessagesEs from '@/assets/data/locales/es.json'

const menuMessages = {
	// en: { ...messagesEn, ...userMessagesEn },
	es: { ...messagesEs, ...userMessagesEs },
}

const IntlProviderWrapper = ({ children }) => {
	const locale = 'es'

	return (
		<IntlProvider key={locale} locale={locale} messages={menuMessages[locale]} defaultLocale={locale}>
			{children}
		</IntlProvider>
	)
}

export { IntlProviderWrapper }
