// ** React Imports
import { useEffect } from 'react'

// ** Third Party Components
import classnames from 'classnames'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import { handleContentWidth, handleMenuCollapsed, handleMenuHidden } from '@/redux/actions/layout'

// ** Styles
import 'animate.css/animate.css'

const LayoutWrapper = ({ children, appLayout, wrapperClass, transition, routeMeta }) => {
	// ** Store Vars
	const dispatch = useDispatch()
	const store = useSelector((state) => state)
	const navbarStore = store.navbar
	const contentWidth = store.layout.contentWidth

	// ** Clean Up Function
	const cleanUp = () => {
		if (routeMeta) {
			if (routeMeta.contentWidth) {
				dispatch(handleContentWidth('full'))
			}
			if (routeMeta.menuCollapsed) {
				dispatch(handleMenuCollapsed(!routeMeta.menuCollapsed))
			}
			if (routeMeta.menuHidden) {
				dispatch(handleMenuHidden(!routeMeta.menuHidden))
			}
		}
	}

	useEffect(() => {
		if (routeMeta) {
			if (routeMeta.contentWidth) {
				dispatch(handleContentWidth(routeMeta.contentWidth))
			}
			if (routeMeta.menuCollapsed) {
				dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
			}
			if (routeMeta.menuHidden) {
				dispatch(handleMenuHidden(routeMeta.menuHidden))
			}
		}
		return () => cleanUp()
	}, [])

	return (
		<div
			className={classnames('app-content content', {
				[wrapperClass]: wrapperClass,
				'show-overlay': navbarStore.query.length,
			})}
		>
			<div className="content-overlay"></div>
			<div
				className={classnames({
					'content-wrapper': !appLayout,
					'content-area-wrapper': appLayout,
					'container p-0': contentWidth === 'boxed',
					[`animate__animated animate__${transition}`]: transition !== 'none' && transition.length,
				})}
			>
				{children}
			</div>
		</div>
	)
}

export default LayoutWrapper

